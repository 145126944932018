<template>
  <div class="mainShop">
    <d-search :searchData="searchData" @searchEvent="searchEvent" @resetEvent="resetEvent" />
    <div class="content">
      <div class="header">
        <div style="width: 100%;">
          <el-button size="medium" type="primary" style="float: right;" @click="handleAddClick">新增</el-button>
        </div>
      </div>
      <d-lable :columnData="columnData" :tableData="tableData" row-key="id">
        <template v-slot:businessType="{ row }">
          <span>{{ getBusinessTypeName(row.businessType)}}</span>
        </template>
        <template v-slot:approvalValue="{ row }">
          <span>{{ getApprovalValueName(row.approvalValue)}}</span>
        </template>
        <template v-slot:handle="{ item }">
          <el-link type="primary" @click="cancelRelation(item)">取消关联</el-link>
        </template>
      </d-lable>
    </div>
    <d-paging :pager="pager" @change="businessList" />
    <el-dialog title="新增" :visible.sync="dialogVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="业务类型" prop="businessType">
          <el-select v-model="form.businessType" placeholder="请选择" size="medium" filterable>
            <el-option v-for="item in searchData.searchItem[0].options" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批流程" prop="approvalValue">
          <el-select v-model="form.approvalValue" placeholder="请选择" size="medium" filterable>
            <el-option v-for="item in searchData.searchItem[1].options" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DSearch from '@/components/d-search'
import DLable from '@/components/d-table'
import DPaging from '@/components/d-paging'

export default {
  data () {
    return {
      form: {
        businessType: '',
        approvalValue: ''
      },
      rules: {
        businessType: [{ required: true, message: '请选择业务类型', trigger: 'change' }],
        approvalValue: [{ required: true, message: '请选择审批流程', trigger: 'change' }],
      },
      dialogVisible: false,
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      childList: [],
      searchData: {
        searchItem: [
          {
            label: '业务类型',
            value: 'businessType',
            type: 'commonSelect',
            placeholder: '',
            filterable: true,
            options: []
          },
          {
            label: '审批流程',
            value: 'approvalValue',
            type: 'commonSelect',
            placeholder: '',
            filterable: true,
            options: []
          },
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {
          businessType: '',
          approvalValue: '',
        }
      },
      columnData: [
        { prop: 'businessType', label: '业务类型', slotProp: true },
        { prop: 'approvalValue', label: '审理流程', slotProp: true },
        { prop: 'createTime', label: '创建时间' },
        { prop: 'createName', label: '操作人' },
        {
          prop: 'operate',
          label: '操作',
          type: {}
        }
      ],
      tableData: []
    }
  },
  components: {
    DSearch,
    DLable,
    DPaging
  },
  mounted () {
    this.init()
  },
  methods: {
    getBusinessTypeName(businessType) {
      if (this.searchData.searchItem[0].options.length) {
        let item = this.searchData.searchItem[0].options.find(item => item.value === businessType)
        return item && item.label
      }
      return ''
    },
    getApprovalValueName(approvalValue) {
      if (this.searchData.searchItem[1].options.length) {
        let item = this.searchData.searchItem[1].options.find(item => item.value === approvalValue)
        return item && item.label
      }
      return ''
    },
    cancelRelation (row) {
      this.$confirm("是否确定取消关联?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: row.id
          }
          this.$api.sysapi.cancelRelation(params).then((res) => {
            this.$message.success(res.msg)
            this.businessList()
          });
        })
        .catch(() => { });
    },
    handleConfirmClick () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$api.sysapi.businessInsert(this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.dialogVisible = false
              this.businessList()
            }
          });
        }
      })
    },
    /**
 * 新建
 **/
    handleAddClick () {
      this.form = {
        businessType: '',
        approvalValue: ''
      }
      this.dialogVisible = true
    },
    /**
     * 搜索按钮点击
     **/
    searchEvent () {
      this.businessList()
    },
    /**
     * 重置按钮点击
     **/
    resetEvent () {
      this.pager.pageNum = 1;
      this.pager.pageSize = 10;
      this.pager.count = 0;
      this.searchData.params.businessType = '';
      this.searchData.params.approvalValue = '';
      this.businessList()
    },

    /**
     * 查询请求
     **/
    businessList () {
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        businessType: this.searchData.params.businessType,
        approvalValue: this.searchData.params.approvalValue,
      }
      this.$api.sysapi.businessList(obj).then(res => {
        if (res.code === 200) {
          this.pager.count = res.data.total
          this.tableData = res.data.list
        }
      })
    },
    getApprovalDynamicList () {
      this.$api.sysapi.approvalDynamicList().then(res => {
        if (res.code === 200) {
          this.searchData.searchItem[0].options = res.data.map(item => {
            return {
              value: item.value,
              label: item.text,
            }
          })
        }
      })
    },
    getAclSelectList () {
      this.$api.sysapi.aclSelectList().then(res => {
        if (res.code === 200) {
          this.searchData.searchItem[1].options = res.data.map(item => {
            return {
              value: item.value,
              label: item.name,
            }
          })
        }
      })
    },
    /**
     * 初始化
     **/
    init () {
      this.getApprovalDynamicList()
      this.getAclSelectList()
      this.businessList()
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.mainShop {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  position: relative;
  box-sizing: border-box;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #fff;

    .el-table {
      flex: 1;
      overflow-y: auto;
    }
  }
}

.el-dialog__header {
  box-shadow: 0px 5px 6px -8px #555;
}

.content {
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;

  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    height: 36px;

    .operation {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;

      div {
        line-height: 28px;
      }

      .el-button {
        margin-left: 20px;
      }

      .el-checkbox {
        height: 32px;
      }
    }
  }

  .el-table {
    margin-top: 10px;
  }
}

.details {
  padding: 0 10px;

  span {
    color: red;
  }
}

.flex_item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .item-label {
    width: 100px;
  }
}

.el-select {
  width: 100%;
}


.d-paging {
  // margin-top: 50px;
  text-align: center;
  background: #fff;
}
</style>
